<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <div>
          <b-card-group deck>
            <b-card header-tag="header" footer-tag="footer">
              <template v-slot:header>
                <b-row>
                <b-col><h6 class="mb-0">Import danh sách trường</h6></b-col>
                <button type="button" class="btn btn-primary" @click="downloadFile()">
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-download" viewBox="0 0 16 16">
                    <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z"></path>
                    <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3z"></path>
                  </svg>
                  Download file mẫu
               </button>
                </b-row>
              </template>
              <div>
                <b-form @submit="onSubmit">
                  <b-form-group id="input-group-4">
                    <label>Vui lòng chọn file đối soát</label>
                    <b-form-file
                      v-model="file"
                      :state="Boolean(file)"
                      placeholder="Choose a file or drop it here...(xls, xlsx)"
                      drop-placeholder="Drop file here..."
                    ></b-form-file>
                  </b-form-group>
                  <b-button type="button" variant="primary" @click="onSubmit">Submit</b-button>
                </b-form>
              </div>
            </b-card>
          </b-card-group>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import Common from "@/core/mixins/common";
import CampaignRepository from "@/core/repositories/CampaignRepository";

export default {
  name: "Create",
  mixins: [Common],
  components: {},
  data() {
    return {
      file: null,
      prov: null,
      extensions: [
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        "application/vnd.ms-excel",
      ],
    };
  },
  computed: {
    ...mapGetters(["layoutConfig"]),

    config() {
      return this.layoutConfig();
    },
  },
  created() {},
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Đối soát Bank" },]);
  },
  methods: {
    onSubmit() {
      // debugger
      if (!this.file) {
        alert("Vui lòng chọn file");
        return false;
      }

      if (this.extensions.indexOf(this.file.type) === -1) {
        alert("File không đúng định dạng, vui lòng kiểm tra lại");
        return false;
      }

      if (this.file.size <= 0) {
        alert("File rỗng, vui lòng kiểm tra lại");
        return false;
      }

      let param = new FormData();
      param.append("file", this.file);
      param.append("provider", this.prov);
      
      CampaignRepository.addFileUniversity(param).then(function(response) {
          if (response.data.error_code) {
            alert(response.data.message);
            return;
          }
          alert("Đẩy file thành công");
          return;
        })
        .catch(function () {
          alert("Có lỗi xảy ra");
      });
    },

    downloadFile() {
      const fileUrl = process.env.BASE_URL + 'media/fileExcel/Import_list_university.xlsx'; // Đường dẫn tới tệp tin trong thư mục "public"

      // Tạo yêu cầu tải xuống bằng cách tạo một thẻ <a> ẩn và kích hoạt sự kiện click trên nó
      const link = document.createElement('a');
      link.href = fileUrl;
      link.download = 'Import_list_university.xlsx'; // Tên tệp tin khi tải xuống
      link.click();
  }
  },
};
</script>
